<template>
  <div v-if="!isLoading">
    <PaymentCOD :grossTotal="grossTotal" :subTotal="subTotal" :loyaltyUsage="loyaltyUsage" :totalItems="totalItems"/>
  </div>
</template>

<script>
import PaymentCOD from '@/components/Purchase/PaymentCOD.vue'

export default {
  name: 'cash-on-delivery',
  components: {
    PaymentCOD,
  },
  data: function () {
    return {
      isLoading: false,
      grossTotal: 0,
      totalItems: 0,
      loyaltyUsage: 0,
      subTotal: 0,
    }
  },
  methods: {
    getOrderDetail() {
      this.isLoading = true
      this.$store.dispatch('purchase/GETDETAIL', {
        order_id: this.$route.params.order_id,
        channel_id: this.$store.state.app.channel_id,
      })
        .then(({ data }) => {
          this.grossTotal = data.gross_total ?? 0
          this.totalItems = data.items.length ?? 0
          this.loyaltyUsage = data.loyalty_usage ?? 0
          this.subTotal = data.sub_total ?? 0
          this.isLoading = false
        })
        .catch(err => {
          this.isLoading = false
          this.$notification.error({
            message: 'Gagal memuat data',
            description: err.response?.data?.message || err.message,
          })
        })
    },
  },
  mounted() {
    this.getOrderDetail()
  },
}
</script>

<style lang="scss">
  .btn-bottom {
    background: #265c9b;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    border: none;
    width: 275px;
    padding: 10px;
  }
</style>
