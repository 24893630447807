<template>
<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.4329 10.8502C19.045 10.4985 18.4402 10.5215 18.0326 10.8502L14.9953 13.2795C14.6238 13.5786 14.1603 13.7397 13.6804 13.7397H9.79167C9.5024 13.7397 9.26573 13.503 9.26573 13.2137C9.26573 12.9244 9.5024 12.6878 9.79167 12.6878H12.3655C12.8882 12.6878 13.3747 12.3295 13.4601 11.8134C13.5686 11.156 13.0624 10.584 12.4214 10.584H7.16194C6.27441 10.584 5.41646 10.8897 4.72615 11.4485L3.19762 12.6878H1.37653C1.08726 12.6878 0.850586 12.9244 0.850586 13.2137V16.3694C0.850586 16.6587 1.08726 16.8953 1.37653 16.8953H13.1051C13.5818 16.8953 14.0453 16.7343 14.42 16.4351L19.3902 12.4577C19.8898 12.0599 19.9293 11.2973 19.4329 10.8502Z" fill="url(#paint0_linear_5835_60898)"/>
  <path d="M8.67383 0H19.7844V2.21336H8.67383V0ZM18.1178 2.7667H9.22936V8.85343C9.22936 9.14694 9.34642 9.42843 9.55478 9.63597C9.76315 9.84351 10.0457 9.96011 10.3404 9.96011H18.1178C18.4125 9.96011 18.6951 9.84351 18.9035 9.63597C19.1119 9.42843 19.2289 9.14694 19.2289 8.85343V2.7667H18.1178ZM16.4513 6.08673H12.007V4.98005H16.4513V6.08673Z" fill="#015CA1"/>
  <defs>
  <linearGradient id="paint0_linear_5835_60898" x1="-6.13464" y1="13.2302" x2="24.4853" y2="13.2302" gradientUnits="userSpaceOnUse">
  <stop stop-color="#5FABE4"/>
  <stop offset="1" stop-color="#015CA1"/>
  </linearGradient>
  </defs>
</svg>
</template>

<script>
export default {
  name: 'DefaultPaymentIcon',
}
</script>

<style>

</style>
