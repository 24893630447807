<template>
  <div class="text-dark">
    <b-modal id="modal-response" v-model="modalVisible" centered class="p-3">
      <div slot="modal-header" class="d-flex align-items-center justify-content-between p-2 w-100">
        <span class="h4 mb-0 text-primary">Rincian Pembayaran</span>
        <span @click="modalVisible = false" class="h4 mb-0 text-primary text-hover">x</span>
      </div>
      <div class="d-flex align-items-center justify-content-between p-2">
        <span class="text-dark">Total Harga {{ `(${totalItems || 0} Produk)` }}</span>
        <span style="color: #F5831F;">Rp. {{ toCurrency(subTotal) }}</span>
      </div>
      <div v-if="loyaltyUsage" class="d-flex align-items-center justify-content-between p-2">
        <span class="text-dark">Coin yang digunakan</span>
        <span style="color: #000000;">- {{ toCurrency(loyaltyUsage) }}</span>
      </div>
      <div slot="modal-footer" class="d-flex align-items-center justify-content-between w-100 p-2">
        <span class="text-dark bold">Total Tagihan</span>
        <span class="bold" style="color: #F5831F;">Rp. {{ toCurrency(grossTotal) }}</span>
      </div>
    </b-modal>
    <h2 class="mb-5">Pembayaran</h2>
    <div class="d-flex justify-content-center">
      <div class="card align-items-center w-75" style="padding: 24px 100px">
        <div class="card-body col">
          <div class="d-flex justify-content-between mb-5">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center">
                <div class="mr-2 payment-title" style="color: #015CA1">Cash On Delivery</div>
                <DefaultPayment />
              </div>
            </div>
            <div class="d-flex flex-column justify-content-end">
              <span class="text-hover text-primary button-link" @click="toggleModal">Lihat Rincian</span>
            </div>
          </div>
          <div class="d-flex mb-3">
            <div class="d-flex flex-column">
              <span>Total Pembayaran</span>
              <div class="d-flex align-items-center justify">
                <span style="color: #F5831F;">Rp. {{ toCurrency(grossTotal) }}</span>
              </div>
            </div>
          </div>
          <div class="mb-5">
            <div class="desc-text-1">Cash On Delivery</div>
            <div class="desc-text-2">Pembayaran akan di lakukan melalui kurir, pada saat barang sampai di lokasi Anda</div>
          </div>
          <div class="card border-0 shadow-none d-flex align-items-end" style="gap: 20px 0px;">
            <button @click="$router.push({ path: '/purchase/' })" class="btn btn-bottom w-auto button-primary">Lihat Daftar Transaksi</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DefaultPayment from '@/components/Icons/DefaultPayment.vue'

export default {
  name: 'PaymentFullCredit',
  components: {
    DefaultPayment,
  },
  props: ['grossTotal', 'totalItems', 'subTotal', 'loyaltyUsage'],
  data: function () {
    return {
      id_params: this.$route.params.order_id,
      data: {},
      activeKey: '',
      modalVisible: false,
      countDown: moment().format('HH:mm:ss'),
    }
  },
  computed: {
    time: function () {
      const time = this.countDown
      const resultTime = moment.utc(time).format('HH:mm:ss')
      return this.countDown <= 0 ? '00:00:00' : resultTime
    },
  },
  methods: {
    getDifferenceInHours(expiredDate, currentDate) {
      const differentTime = expiredDate - currentDate
      const checkIfExpired = differentTime <= 0 ? 0 : moment(expiredDate) - moment(currentDate)
      // const result = moment.utc(checkIfExpired).format('HH:mm:ss')
      return checkIfExpired
    },
    getOrderDetail() {
      this.$store.dispatch('purchase/GETINTRUCTIONSPAYMENT', {
        channel_id: this.$store.state.user.user_data.channel_id,
        order_id: this.id_params,
      })
        .then(({ data }) => {
          const splitDate = data.expired_at.split(' ')
          const endDate = {
            day: moment(splitDate[0]).format('dddd'),
            date: moment(splitDate[0]).format('DD MMMM YYYY'),
            hour: moment(splitDate[1], 'HH:mm:ss').format('HH:mm'),
            countDown: [splitDate[0], splitDate[1]].join(' '),
          }
          const expiredDate = moment(endDate.countDown).format('YYYY-MM-DD HH:mm:ss')
          const hours = this.getDifferenceInHours(new Date(expiredDate).getTime(), new Date().getTime())
          this.countDown = hours
          const newInstructions = (data && data.instructions) || (data && data.instructions && data.instructions.instructions) || {}
          return this.$store.dispatch('purchase/GETINVOICEDETAIL', {
            order_id: this.id_params,
          }).then((res) => {
            const result = res.data
            this.data = {
              ...data,
              ...result,
              newInstructions,
              expired: `${endDate.day}, ${endDate.date} - ${endDate.hour}`,
              total: Intl.NumberFormat('en-US').format(result.gross_amount),
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    changeActiveKey(key) {
      this.activeKey = key
    },
    toggleModal() {
      this.modalVisible = !this.modal
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
  },
  mounted() {
    this.getOrderDetail()
    setInterval(() => {
      this.countDown = moment(this.countDown).subtract(1, 'seconds')
    }, 1000)
  },
  watch: {
    // this.countDownTimer()
  },
}
</script>

<style scoped>
.ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.text-hover:hover {
  color: #015CA1;
  cursor: pointer;
}
.button-link {
  font-size: 14px;
}

.button-primary {
  padding: 4px 24px;
  border-radius: 8px;
  font-size: 16px;
}
.bold {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.payment-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}
.desc-text-1 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #000000;
}
.desc-text-2 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #929292;
}
</style>
